import React, { useState, useEffect, useContext } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import "./style.css";
import { toast } from "react-toastify";
import { Link, navigate } from "gatsby";
import { useMutation } from "@apollo/react-hooks";
import { CUSTOMER_LOGIN } from "./graphql/mutations";
import { isBrowser } from "../../utils/general";
import { LanguageContext } from "../../lang";

const LoginSchema = Yup.object().shape({
  email: Yup.string("Enter your Email/Phone Number")
    .required("Email/Username is required")
    .test("email", "Enter Valid Phone/Email", function (value) {
      const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      let isValidEmail = emailRegex.test(value);
      if (isValidEmail || (value && value.length >= 3)) {
        return true;
      }
      return false;
    }),
  password: Yup.string()
    .required("Please enter password")
    .min(5)
    .max(255)
    .label("Password"),
});

const intialValue = {
  email: "",
  password: "",
};

const Login = (props) => {
  const {
    translations: { login },
  } = useContext(LanguageContext);
  const { rememberText, forgotText, dontHaveText, btnText } = login;

  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [handleLogin, { data, loading, error }] = useMutation(CUSTOMER_LOGIN);
  const handleSignIn = ({ email, password }) => {
    console.log(email);
    console.log(password);
    handleLogin({
      variables: {
        data: {
          email,
          password,
        },
      },
    });
  };
  if (data) {
    if (isCheckBoxChecked) {
      isBrowser() &&
        localStorage.setItem("token", JSON.stringify(data.customerLogin.token));
    } else {
      isBrowser() &&
        sessionStorage.setItem(
          "token",
          JSON.stringify(data.customerLogin.token)
        );
    }
    navigate("/my-account");
  }
  if (error) {
    toast.error("Authentication Failed ", {
      autoClose: 1200,
    });
    console.log("graphql error is", error);
  }

  return (
    <div className="container-fluid d-flex hm-login-bg justify-content-center align-items-center">
      <div id="login" style={{ marginTop: 150, marginBottom: 150 }}>
        <div className="loginTextView">
          <p>Login</p>
        </div>
        <div className="inputstyleview">
          <Formik
            initialValues={intialValue}
            validationSchema={LoginSchema}
            onSubmit={(values) => handleSignIn(values)}
          >
            {({ values, errors, touched, handleSubmit }) => (
              <>
                <Form className="formStyle">
                  <Field
                    className="inputStyle"
                    type="email"
                    name="email"
                    placeholder="E-Mail-Addresse required"
                  />
                  {errors.email && touched.email ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.email}</p>
                    </div>
                  ) : null}
                  <Field
                    className="inputStyle"
                    type="password"
                    name="password"
                    placeholder="Passwort is required"
                  />
                  {errors.password && touched.password ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.password}</p>
                    </div>
                  ) : null}
                  <div className="smallView">
                    <div className="forgotPasswordView">
                      <div className="rememberMeView">
                        <input
                          onClick={() => {
                            setIsCheckBoxChecked(!isCheckBoxChecked);
                          }}
                          type="checkbox"
                          name="rememberMe"
                          value={isCheckBoxChecked}
                        />
                        <p className="rememberMe">{rememberText}</p>
                      </div>
                      <Link to="/forgot-password">
                        <p className="forgotPassword">{forgotText}</p>
                      </Link>
                    </div>
                  </div>
                  <div className="loginButtonStyle">
                    <p onClick={handleSubmit} className="loginButtonText">
                      {loading ? <div className="spinner"></div> : btnText}
                    </p>
                  </div>
                </Form>
              </>
            )}
          </Formik>
          <div className="socialLoginView">
            <a href={`${process.env.GATSBY_SOCIAL_AUTH}/facebook/`}>
              <div className="faceboookBtn">
                <i className="fa fa-facebook-f faceboookIcon"></i>
                <p className="facebookBtnText">Facebook</p>
              </div>
            </a>
            <a href={`${process.env.GATSBY_SOCIAL_AUTH}/google/`}>
              <div className="gmailBtnn">
                <span className="gmaiilIcon"></span>
                <p className="gmailBtnText">Gmail</p>
              </div>
            </a>
          </div>
          <Link to="/signup">
            <p className="alreadyMemberText">{dontHaveText}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
