import gql from 'graphql-tag'

export const CUSTOMER_LOGIN = gql`
	mutation($data: loginInput!) {
		customerLogin(data: $data) {
			_id
			token
		}
	}
`
export const CREATE_CUSTOMER = gql`
	mutation($data: CreateCustomerInput!) {
		createCustomer(data: $data) {
			id
		}
	}
`
export default {
	CUSTOMER_LOGIN
}
