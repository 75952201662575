import React, { useState, useContext } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link } from "gatsby";
import { toast } from "react-toastify";
import "./style.css";
import { CREATE_CUSTOMER } from "./graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import { navigate } from "gatsby";
import { LanguageContext } from "../../lang";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const signupSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Please Enter Your Phone Number"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please Enter Your Email"),
  name: Yup.string().required("Please enter Name").min(5),
  password: Yup.string()
    .required("Please enter password")
    .min(5)
    .max(255)
    .label("Password"),
  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Please Confirm Password"),
});

const intialValue = {
  phone: "",
  email: "",
  password: "",
  cpassword: "",
  name: "",
};

export default (props) => {
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [createCustomerMutationTrigger, { data, loading, error }] = useMutation(
    CREATE_CUSTOMER
  );
  const handleSignIn = ({ email, phone, password, name }) => {
    if (isCheckBoxChecked === false) {
      alert("Terms And Services are not checked");
    } else {
      createCustomerMutationTrigger({
        variables: {
          data: {
            username: name,
            email: email,
            password: password,
            phoneNumber: phone,
          },
        },
      });
    }
  };
  if (loading) {
    console.log("loading from graphql", loading);
  }
  if (error) {
    toast.error(error.message, {
      autoClose: 1200,
    });
  }
  if (data) {
    navigate("/login");
  }

  const {
    translations: { signup },
  } = useContext(LanguageContext);
  const {
    heading,
    name,
    email,
    phone,
    password,
    repeatPassword,
    agree,
    btn,
    alreadyMember,
  } = signup;

  return (
    <div className="container-fluid d-flex hm-login-bg justify-content-center align-items-center">
      <div id="signup" style={{ marginTop: 150, marginBottom: 150 }}>
        <div className="loginTextView">
          <p>{heading}</p>
        </div>
        <div className="inputstyleview">
          <Formik
            initialValues={intialValue}
            validationSchema={signupSchema}
            onSubmit={(values) => handleSignIn(values)}
          >
            {({ values, errors, touched, handleSubmit }) => (
              <>
                <Form className="formStyle">
                  <Field
                    className="inputStyle"
                    type="text"
                    name="name"
                    placeholder={name}
                  />
                  {errors.name && touched.name ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.name}</p>
                    </div>
                  ) : null}
                  <Field
                    className="inputStyle"
                    type="text"
                    name="email"
                    placeholder={email}
                  />
                  {errors.email && touched.email ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.email}</p>
                    </div>
                  ) : null}
                  <Field
                    className="inputStyle"
                    type="text"
                    name="phone"
                    placeholder={phone}
                  />
                  {errors.phone && touched.phone ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.phone}</p>
                    </div>
                  ) : null}
                  <Field
                    className="inputStyle"
                    type="password"
                    name="password"
                    placeholder={password}
                  />
                  {errors.password && touched.password ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.password}</p>
                    </div>
                  ) : null}
                  <Field
                    className="inputStyle"
                    type="password"
                    name="cpassword"
                    placeholder={repeatPassword}
                  />
                  {errors.cpassword && touched.cpassword ? (
                    <div className="bg-danger my-1 p-0 col-12 rounded">
                      <p className="text-white p-1 m-0">{errors.cpassword}</p>
                    </div>
                  ) : null}
                  <div className="smallView">
                    <div className="acceptTermAndConditions">
                      <input
                        onClick={() => {
                          console.log("state of checkbox", isCheckBoxChecked);
                          setIsCheckBoxChecked(!isCheckBoxChecked);
                        }}
                        type="checkbox"
                        name="vehicle1"
                        value={isCheckBoxChecked}
                      />
                      <p className="termAndConditionText">{agree}</p>
                    </div>
                  </div>
                </Form>

                <div onClick={handleSubmit} className="loginButtonStyle">
                  <p className="loginButtonText">
                    {loading ? <div className="spinner"></div> : btn}
                  </p>
                </div>
              </>
            )}
          </Formik>
          <div className="socialLoginView">
            <div className="faceboookBtn">
              <i className="fa fa-facebook-f faceboookIcon"></i>
              <p className="facebookBtnText">Facebook</p>
            </div>
            <div className="gmailBtnn">
              <span className="gmaiilIcon"></span>
              <p className="gmailBtnText">Gmail</p>
            </div>
          </div>
          <Link to="/login">
            <p className="alreadyMemberText">{alreadyMember}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = state => ({
// 	isLoading: state.auth.loading,
// 	user: state.auth.user,
// 	err: state.auth.error
// });

// export default connect(mapStateToProps, null)(signUp);
